import TRTC from 'trtc-js-sdk';

const INIT    =  'INIT';
const JOINED  =  'JOINED';
const LIVING  =  'LIVING';

function startPlay(shareStream, el) {
      // shareStream.play(el, { objectFit: 'contain' }).then(() => {
      //       console.log('正在播放中...');
      // }).catch((error) => {
      //       const errorCode = error.getCode();
      //       if (errorCode === 0x4042) {
      //             console.log('0x4042 错误');
      //             const resume = () => {
      //                   shareStream.resume();
      //                   el.removeEventListener('click', resume);
      //             }
      //             el.addEventListener('click', resume);
      //       }
      // });
      console.log(shareStream, el);
}

export function client(userId, sdkAppId, userSig) {

      let state = INIT //  ['init', 'joined', 'living', 'leaved']
      const client = TRTC.createClient({
            mode: 'rtc',
            sdkAppId,
            userId,
            userSig
      });

      let shareStream;

      return {
            joinRoom(roomId) {
                  roomId = parseInt(roomId);
                  if (state === INIT) {
                        client.join({ roomId }).then(() => {
                              state = JOINED;
                              this.onJoined && this.onJoined(state);
                        }).catch((error) => {
                              console.error('进房失败 请刷新一下网页再试一次', error);
                              alert('进房失败 请刷新一下网页再试一次');
                              history.back();
                        });
                  }
            },

            startSharing(el) {
                  if (state !== JOINED) {
                        return;
                  }
                  this.onLiveStarting && this.onLiveStarting();
                  shareStream = TRTC.createStream({ audio: false, screen: true, userId });
                  shareStream.setScreenProfile('720p');

                  shareStream.on('screen-sharing-stopped', () => {
                        this.stopSharing();
                  });

                  shareStream.initialize().then(() => {
                        client.publish(shareStream).then(() => {
                              console.log('本地流发布成功');
                              startPlay(shareStream, el);
                              state = LIVING;
                              this.onLiving && this.onLiving(state);
                        }).catch((error) => {
                              console.error('本地流发布失败', error);
                        });
                  }).catch((error) => {
                        switch (error.name) {
                              case 'NotReadableError':
                                // 提醒用户确保系统允许当前浏览器获取屏幕内容
                                alert('请允许当前浏览器获取屏幕内容');
                                return;
                              case 'NotAllowedError':
                                if (error.message === 'Permission denied by system') {
                                  // 提醒用户确保系统允许当前浏览器获取屏幕内容
                                  alert('请允许当前浏览器获取屏幕内容');
                                } else {
                                  // 用户拒绝/取消屏幕分享
                                  alert('您拒绝了屏幕分享功能, 请开启');
                                }
                                return;
                              default:
                                // 初始化屏幕分享流时遇到了未知错误，提醒用户重试
                                alert('初始化屏幕分享流时遇到了未知错误, 请重试');
                                return;
                        }
                  });
            },

            stopSharing() {
                  return new Promise((resolve) => {
                        if (state === LIVING) {
                              this.onLiveStoping && this.onLiveStoping(state);
                              client.unpublish(shareStream).then(() => {
                                    shareStream.close();
                                    state = JOINED;
                                    this.onUnLiving && this.onUnLiving(state);
                                    resolve();
                              }).catch((error) => {
                                    console.log(error);
                                    resolve();
                              });
                        } else {
                              resolve();
                        }
                  });
            },

            leaveRoom() {
                  return new Promise((resolve, reject) => {
                        client.leave().then(() => {
                              state = INIT;
                              this.onLeaveRoom && this.onLeaveRoom(state);
                              resolve();
                        }).catch(error => {
                              console.error('leaving room failed: ' + error);
                              reject(error);
                        });
                  });

            },
            canJoinRoom() {
                  return state === INIT;
            },
            canStartLiving() {
                  return state === JOINED;
            },
            canStopLiving() {
                  return state === LIVING;
            },
            canLeaveRoom() {
                  return state === JOINED;
            },
      }
}